import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Navigation } from 'hds-react';
import PlaygroundPreview from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
import InternalLink from '../../../components/InternalLink';
export const _frontmatter = {
  "slug": "/components/navigation",
  "title": "Navigation",
  "navTitle": "Navigation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}<a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}>{`Example`}<a parentName="h3" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <Navigation title="Helsinki Design System" menuToggleAriaLabel="menu" skipTo="#content" skipToContentLabel="Skip to content" mdxType="Navigation">
    <Navigation.Row>
      <Navigation.Item label="Item" active />
      <Navigation.Item label="Item" />
      <Navigation.Item label="Item" />
      <Navigation.Item label="Item" />
      <Navigation.Item label="Item" />
      <Navigation.Item label="Item" />
    </Navigation.Row>
  </Navigation>
    </PlaygroundPreview>
    <h3 {...{
      "id": "principles",
      "style": {
        "position": "relative"
      }
    }}>{`Principles`}<a parentName="h3" {...{
        "href": "#principles",
        "aria-label": "principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`It is strongly recommended to always include the navigation component in your service.`}</strong></li>
      <li parentName="ul">{`Always position navigation to the top of the page. Keep the navigation in place so the user can always easily locate it. It is recommended to include navigation on every page of the service.`}</li>
      <li parentName="ul">{`Navigation component is built to follow `}<InternalLink href="/foundation/design-tokens/breakpoints" mdxType="InternalLink">{`HDS breakpoint tokens`}</InternalLink>{`. While it is recommended to follow HDS breakpoint tokens, you may alter navigation width to fit your service's needs.`}</li>
      <li parentName="ul"><strong parentName="li">{`Try to keep the order of actions (search, user, language select) consistent in navigation.`}</strong>{` Do not change the default order without a good reason.`}
        <ul parentName="li">
          <li parentName="ul">{`If there are less than three available languages and the services navigation is simple, available languages can be listed as links in the action row instead of a language select dropdown for better usability. `}<strong parentName="li">{`Remember to provide a lang-attribute for each language option to help assistive technology to read the language correctly`}</strong>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Keep navigation menu link labels clear and concise. Prefer max 1-2 word long labels. Avoid starting multiple menu link labels with the same word.`}</li>
    </ul>
    <h3 {...{
      "id": "variations",
      "style": {
        "position": "relative"
      }
    }}>{`Variations`}<a parentName="h3" {...{
        "href": "#variations",
        "aria-label": "variations permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "default-2-rows",
      "style": {
        "position": "relative"
      }
    }}>{`Default (2 rows)`}<a parentName="h4" {...{
        "href": "#default-2-rows",
        "aria-label": "default 2 rows permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`The default navigation includes a separate line for navigation menu links. Therefore it is the ideal choice for most services where navigation structure is more complicated.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <Navigation title="Helsinki Design System" menuToggleAriaLabel="menu" skipTo="#content" skipToContentLabel="Skip to content" mdxType="Navigation">
    <Navigation.Row>
      <Navigation.Item label="Item" active />
      <Navigation.Item label="Item" />
      <Navigation.Item label="Item" />
      <Navigation.Item label="Item" />
      <Navigation.Item label="Item" />
      <Navigation.Item label="Item" />
    </Navigation.Row>
  </Navigation>
    </PlaygroundPreview>
    <h4 {...{
      "id": "small-1-row",
      "style": {
        "position": "relative"
      }
    }}>{`Small (1 row)`}<a parentName="h4" {...{
        "href": "#small-1-row",
        "aria-label": "small 1 row permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`A service can opt to use smaller, 1 row navigation if 2 row navigation is too heavy for its purpose. Smaller navigation is optimal for services that do not have a complicated navigation structure (only 1-3 links in navigation) and do not need many navigation features such as search, profile, and language selection.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <Navigation title="Helsinki Design System" menuToggleAriaLabel="menu" skipTo="#content" skipToContentLabel="Skip to content" mdxType="Navigation">
    <Navigation.Row variant="inline">
      <Navigation.Item label="Item" active />
      <Navigation.Item label="Item" />
      <Navigation.Item label="Item" />
    </Navigation.Row>
  </Navigation>
    </PlaygroundPreview>
    <h4 {...{
      "id": "navigation-actions",
      "style": {
        "position": "relative"
      }
    }}>{`Navigation actions`}<a parentName="h4" {...{
        "href": "#navigation-actions",
        "aria-label": "navigation actions permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`HDS Navigation component supports many commonly used features out of the box. The main navigation bar can be configured to include search, language selection and user profile actions. You may also easily customize the action row with your own actions.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <Navigation title="Helsinki Design System" menuToggleAriaLabel="menu" skipTo="#content" skipToContentLabel="Skip to content" mdxType="Navigation">
    <Navigation.Actions>
      <Navigation.Search searchLabel="Search" searchPlaceholder="Search page" />
      <Navigation.User label="Sign in" />
      <Navigation.LanguageSelector label="FI">
        <Navigation.Item lang="fi" label="Suomeksi" />
        <Navigation.Item lang="sv" label="På svenska" />
        <Navigation.Item lang="en" label="In English" />
      </Navigation.LanguageSelector>
    </Navigation.Actions>
  </Navigation>
    </PlaygroundPreview>
    <h4 {...{
      "id": "colour-variations",
      "style": {
        "position": "relative"
      }
    }}>{`Colour variations`}<a parentName="h4" {...{
        "href": "#colour-variations",
        "aria-label": "colour variations permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`HDS Navigation offers two variations with different background colours - one with white and one with black text elements. Use variation that achieves better contrast with the brand background colour of your choice. When customising colours, refer to `}<InternalLink href="/foundation/design-tokens/colour" mdxType="InternalLink">{`colour guidelines`}</InternalLink>{` to ensure accessibility.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <Navigation title="Helsinki Design System" menuToggleAriaLabel="menu" skipTo="#content" skipToContentLabel="Skip to content" mdxType="Navigation">
    <Navigation.Row variant="inline">
      <Navigation.Item label="Item" active />
      <Navigation.Item label="Item" />
      <Navigation.Item label="Item" />
    </Navigation.Row>
  </Navigation>
  <br />
  <Navigation theme="dark" title="Helsinki Design System" menuToggleAriaLabel="menu" skipTo="#content" skipToContentLabel="Skip to content" mdxType="Navigation">
    <Navigation.Row variant="inline">
      <Navigation.Item label="Item" active />
      <Navigation.Item label="Item" />
      <Navigation.Item label="Item" />
    </Navigation.Row>
  </Navigation>
    </PlaygroundPreview>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      